<script setup>
  const { mdAndUp } = useDisplay()
  async function close_menu_and_navigate() {
    document.querySelector('div.v-overlay.v-menu > div').style.display = 'none'
    await navigateTo("/portfolio");
  }
  defineProps({
    overlay: Boolean
  })
</script>

<template>
  <header v-if="mdAndUp" :class="{overlaymenu: overlay}" class="d-flex w-100 px-5 py  -12 align-center" height="70">
    <div class="me-auto" />
    <!-- <nuxt-link to="/" class="me-auto"><v-img src="/LOGO-04.svg" width="100" height="50" class="ml-10"/></nuxt-link> -->
    <v-btn to="/" size="x-large" variant="plain">Hlavní</v-btn>
    <v-btn to="/services" size="x-large" variant="plain">Služby a ceny</v-btn>
    <v-btn to="/about" size="x-large" variant="plain">O mně</v-btn>
    <!-- <v-btn to="/" size="x-large" variant="plain">Kontakty</v-btn> -->
    <!-- <v-btn to="/about" size="large"  variant="text">About</v-btn>
    <v-menu open-on-hover eager open-delay="150" ref="menu">
      <template v-slot:activator="{ props }">
        <v-btn to="/portfolio" size="large" variant="text" v-bind="props" @click="close_menu_and_navigate">Portfolio</v-btn>
      </template>
      <v-list>
        <v-list-item to="/about">trstrtr</v-list-item>
        <v-list-item>rsstra</v-list-item>
      </v-list>
    </v-menu> -->
  </header>
  <v-menu v-else transition="slide-y-transition" :class="{overlaypopup: overlay}">
    <template v-slot:activator="{ props }">
      <v-btn :class="{overlaybutton: overlay}" icon="mdi-menu" density="comfortable" size="x-large" class="ma-5" v-bind="props"/>
    </template>
    <v-list>
      <v-list-item to="/">Hlavní</v-list-item>
      <v-list-item to="/services">Služby a ceny</v-list-item>
      <v-list-item to="/about">O mně</v-list-item>
    </v-list>
  </v-menu>
</template>

<style scoped>
  .v-btn {
    text-transform: none
  }
  .overlaymenu {
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    z-index: 100;
  }
  .overlaybutton {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(200, 200, 200, 0.1);
    z-index: 100;
  }
  div.overlaypopup div.v-list {
    background: rgba(200, 200, 200, 0.1);
  }
</style>
